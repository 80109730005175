import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';
import { observer } from 'mobx-react-lite';
import styled, { useTheme } from 'styled-components/native';

import Input from '$components/DataEntry/Input';
import Alert from '$components/Feedback/Alert';
import Button from '$components/General/Button';
import Text from '$components/General/Text';
import Frame from '$components/Layout/Frame';
import A from '$components/Navigation/A';

import LogoImage from '$assets/logo.png';
import { useLoginMutation } from '$graphql';
import useStore from '$store';

import { version } from '../../../package.json';

const Login: React.FC = () => {
  const store = useStore();

  const [credentials, setCredentials] = React.useState({
    email: '',
    password: '',
  });

  // Handle login
  const [login, { data, loading, error }] = useLoginMutation();
  const handleLogin = React.useCallback(() => login({ variables: credentials }).catch(() => {}), [credentials]);

  React.useEffect(() => {
    if (!data || !data.login) return;
    store.setToken(data.login);
  }, [data]);

  // Theme for background color
  const theme = useTheme();
  const bColor = theme.primary.desaturate(0.75);

  const { t } = useTranslation('login');

  return (
    <LinearGradient colors={[bColor.darken(0.5).hex(), bColor.darken(0.75).hex()]} style={{ flex: 1 }}>
      <Root behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <Frame style={{ width: '100%', maxWidth: 380 }}>
          <Logo source={LogoImage} />

          <Text centered h1 spacedBottom>
            {t('welcome')}
          </Text>

          <Text centered faded spacedBottom={2}>
            {t('welcome2')}
          </Text>

          {error && <Alert error message={t('failed')} description={t('failed_message')} spacedBottom />}

          <Input
            value={credentials.email}
            onChangeText={(value) => setCredentials((old) => ({ ...old, email: value }))}
            keyboardType="email-address"
            placeholder={t('email')}
            disabled={loading}
            spacedBottom
          />

          <Input
            value={credentials.password}
            onChangeText={(value) => setCredentials((old) => ({ ...old, password: value }))}
            placeholder={t('password')}
            secureTextEntry
            disabled={loading}
            spacedBottom
          />

          {/*<A faded to="https://www.google.com.br">
            {t('forgot_password')}
          </A>*/}

          <Button spacedY={3} paddedY textProps={{ h3: true }} onPress={handleLogin} disabled={loading}>
            {t('login')}
          </Button>

          <FooterLinks>
            <A faded spacedX to="https://idaction.com.br/tos">
              {t('tos')}
            </A>
            <A faded spacedX to="https://idaction.com.br/privacy">
              {t('privacy')}
            </A>
          </FooterLinks>

          <Text disabled p1 semibold centered spacedTop={4} spacedBottom={0.5}>
            ID Action - O seu marketing no piloto automático
          </Text>

          <Text disabled p2 centered>
            v{version}
          </Text>
        </Frame>
      </Root>
    </LinearGradient>
  );
};

export default observer(Login);

const Root = styled.KeyboardAvoidingView`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const Logo = styled.Image`
  width: 160px;
  height: 160px;
  margin: 32px 0;
  align-self: center;
`;

const FooterLinks = styled.View`
  justify-content: center;
  flex-direction: row;
`;

import React from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import styled from 'styled-components/native';

import Text from '$components/General/Text';

interface Props {
  style: StyleProp<ViewStyle>;
  badge?: number;
  small?: boolean;
}

const Badge: React.FC<Props> = ({ badge, small, style }) => {
  // Preserva o último valor do badge
  const lastBadge = React.useRef(0);

  // Animações
  const showAnim = React.useRef(new Animated.Value(0)).current;
  const scaleAnim = React.useRef(new Animated.Value(1)).current;

  // Anima o badge quando seu valor é alterado
  React.useEffect(() => {
    if (lastBadge.current && badge) {
      // Faz um bounce já que havia um número anteriormente
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 1.2,
          duration: 250,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.spring(showAnim, {
        toValue: badge ? 1 : 0,
        useNativeDriver: true,
      }).start();
    }

    lastBadge.current = badge || 0;
  }, [badge]);

  // Estilo com animações
  const animatedStyle = {
    opacity: showAnim,
    transform: [
      { translateY: showAnim.interpolate({ inputRange: [0, 1], outputRange: [8, 0] }) },
      { scale: scaleAnim },
    ],
  };

  // Renderiza o small badge
  if (small) {
    return <SmallRoot style={[style, animatedStyle]} />;
  }

  // Renderiza o badge completo
  return (
    <Root style={[style, animatedStyle]}>
      <Text black centered style={{ fontSize: 10 }}>
        {badge || lastBadge.current}
      </Text>
    </Root>
  );
};

const Root = styled(Animated.View)`
  position: absolute;
  min-width: 18px;
  min-height: 18px;
  padding: 2px 4px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
`;

const SmallRoot = styled(Animated.View)`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

export default Badge;

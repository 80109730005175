import React from 'react';
import { Dimensions, Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Carousel, { Pagination } from 'react-native-snap-carousel';

import { Video } from 'expo-av';
import styled, { useTheme } from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Loader from '$components/Feedback/Loader';
import Touchable from '$components/Navigation/Touchable';

import { goBack } from '$navigation';

interface Props {
  route: any;
}

const VideosViewer: React.FC<Props> = ({ route }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const dim = Dimensions.get('window');

  const videoRef = React.useRef<any>();
  const [active, setActive] = React.useState(route.params.index ?? 0);
  const [loading, setLoading] = React.useState(true);

  function handleSnapToItem(index: number) {
    setLoading(true);
    setActive(index);
  }

  function handleLoad(data: any) {
    setLoading(false);
    videoRef.current?.playAsync();
  }

  return (
    <Root style={{ paddingBottom: 72 + insets.bottom }}>
      {Platform.OS === 'android' && (
        <CloseButtonViewAndroid>
          <Touchable onPress={() => goBack()}>
            <AntDesign name="closecircle" color="#dddddd" size={22} />
          </Touchable>
        </CloseButtonViewAndroid>
      )}

      {Platform.OS === 'ios' && (
        <CloseButtonViewIos>
          <Touchable onPress={() => goBack()}>
            <CloseButtonIosRoot>
              <AntDesign name="close" color="#ffffffc0" size={19} />
            </CloseButtonIosRoot>
          </Touchable>
        </CloseButtonViewIos>
      )}

      <Carousel
        data={route.params.videos}
        firstItem={route.params.index}
        keyExtractor={(item, i) => i.toString()}
        sliderWidth={dim.width}
        itemWidth={dim.width}
        onSnapToItem={handleSnapToItem}
        renderItem={({ item, index }: any) => {
          if (active !== index) return <View style={{ flex: 1 }} />;

          return (
            <View style={{ flex: 1 }}>
              {loading && (
                <LoaderView>
                  <Loader size="large" />
                </LoaderView>
              )}
              <Video
                ref={videoRef}
                useNativeControls
                style={{ flex: 1 }}
                source={{ uri: item }}
                resizeMode="contain"
                onLoad={handleLoad}
              />
            </View>
          );
        }}
      />

      <Pagination
        dotsLength={route.params.videos.length}
        activeDotIndex={active}
        dotStyle={{ width: 12, height: 12, borderRadius: 6, backgroundColor: theme.primary.hex() }}
        containerStyle={{ paddingVertical: 0, paddingTop: 8 }}
      />
    </Root>
  );
};

const Root = styled.View`
  flex: 1;
  background-color: #000;
`;

const LoaderView = styled.View`
  position: absolute;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`;

const CloseButtonViewAndroid = styled.View`
  position: absolute;
  z-index: 9999;
  right: 16px;
  top: 16px;
`;

const CloseButtonViewIos = styled.View`
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  padding: 6px 0;
`;

const CloseButtonIosRoot = styled.View`
  background-color: #363636aa;
  padding: 6px 14px;
  border-radius: 8px;
`;

export default VideosViewer;

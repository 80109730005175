import React from 'react';
import { Dimensions } from 'react-native';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';

import Text from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { useSelectedAccountQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';

interface Props {}

const AccountSelector: React.FC<Props> = () => {
  const store = useStore();
  const dim = Dimensions.get('window');

  const { data: selectedAccount, loading } = useSelectedAccountQuery({
    variables: { id: store.selectedAccountId ?? '' },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Root>
      {!loading && (
        <Touchable onPress={() => navigate('Accounts')}>
          <Selector>
            <Text padded h4 semibold style={{ maxWidth: dim.width * 0.6 }} numberOfLines={1}>
              {selectedAccount?.account.name ?? 'Nenhuma conta selecionada'}
            </Text>
            {!!selectedAccount?.account.logoUrl && <AccountLogo source={{ uri: selectedAccount.account.logoUrl }} />}
          </Selector>
        </Touchable>
      )}
    </Root>
  );
};

const Root = styled.View`
  flex-direction: row;
  justify-content: center;
`;
const Selector = styled.View`
  margin: 4px;
  padding: 2px 4px;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  background-color: #00000020;
`;

const AccountLogo = styled.Image`
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

export default observer(AccountSelector);

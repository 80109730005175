import React from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import styled, { useTheme } from 'styled-components/native';

import Text, { Props as TextProps } from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { getBaseStyles, StyleProps } from '$logic/style';

interface Props extends StyleProps {
  style?: StyleProp<ViewStyle>;
  textProps?: TextProps;
  success?: boolean;
  error?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

const Button: React.FC<Props> = (props) => {
  const ref = React.useRef(null);
  const hovered = useHover(ref);

  const theme = useTheme();

  let color = theme.primary;
  if (props.error) color = theme.error;
  if (props.success) color = theme.success;
  if (hovered) color = color.darken(0.1).desaturate(0.1);

  return (
    <Touchable onPress={props.disabled ? undefined : props.onPress}>
      <Root {...props} ref={ref} style={[{ backgroundColor: color.hex() }, props.style]}>
        <Text centered {...props.textProps} onPress={undefined} disabled={props.disabled}>
          {props.children}
        </Text>
      </Root>
    </Touchable>
  );
};

const Root = styled.View<Props>`
  ${getBaseStyles};
  border-radius: 2px;
  opacity: ${({ disabled }) => (disabled ? 0.37 : 1)};
`;

export default Button;

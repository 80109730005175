import React from 'react';
import { Platform, TextInput, TextInputProps } from 'react-native';

import styled from 'styled-components/native';

import { getBaseStyles, StyleProps } from '$logic/style';

interface Props extends StyleProps, TextInputProps {
  disabled?: boolean;
}

const Input = React.forwardRef<TextInput, React.ComponentPropsWithoutRef<React.ElementType<Props>>>((props, ref) => {
  return <El {...props} ref={ref} placeholderTextColor="rgba(255,255,255,0.37)" />;
});

const El = styled.TextInput`
  ${getBaseStyles};
  padding: 8px 12px;
  background-color: #00000060;
  height: 40px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  border-radius: 2px;
  ${Platform.OS === 'web' ? ' outline-style: none;' : ''}
`;

export default Input;

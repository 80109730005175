import React from 'react';
import { Alert } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import styled from 'styled-components/native';

import { FontAwesome } from '@expo/vector-icons';

import Input from '$components/DataEntry/Input';
import Loader from '$components/Feedback/Loader';
import Button from '$components/General/Button';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useUserFeedbackMutation } from '$graphql';
import { goBack } from '$navigation';
import { getMaxConstraints } from '$logic/style';

const Feedback: React.FC = () => {
  const [text, setText] = React.useState('');
  const [like, setLike] = React.useState('');

  const [userFeedback, { loading }] = useUserFeedbackMutation();

  function handleSendFeedback() {
    userFeedback({ variables: { payload: JSON.stringify({ like, feedback: text }) } }).finally(() => {
      goBack();
      Alert.alert('Muito obrigado!', 'Com o seu feedback podemos continuar sempre melhorando.', [{ text: 'Fechar' }]);
    });
  }

  return (
    <Screen>
      <KeyboardAwareScrollView
        enableOnAndroid
        contentContainerStyle={{ flex: 1, padding: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        extraHeight={200}
      >
        <Text h2 bold spacedTop>
          Compartilhe seu feedback conosco!
        </Text>
        <Text faded>
          Estamos sempre buscando aprimorar e contamos com você para enviar seu feedback sobre a nova versão do nosso
          aplicativo.
        </Text>

        <Text h4 spacedTop={3}>
          Você está gostando da nova versão do aplicativo?
        </Text>

        <Actions>
          <Touchable onPress={() => setLike('like')}>
            <Action style={{ backgroundColor: like === 'like' ? '#09b27f' : undefined }}>
              <FontAwesome name="thumbs-o-up" size={28} color={like === 'like' ? '#FFF' : '#09b27f'} />
            </Action>
          </Touchable>
          <Touchable onPress={() => setLike('dislike')}>
            <Action style={{ backgroundColor: like === 'dislike' ? '#d64848' : undefined }}>
              <FontAwesome name="thumbs-o-down" size={28} color={like === 'dislike' ? '#FFF' : '#d64848'} />
            </Action>
          </Touchable>
        </Actions>

        <Text h4 spacedBottom spacedTop>
          Quer nos dizer algo? Escreva no campo abaixo.
        </Text>

        <Input
          multiline
          placeholder="Escreva aqui o seu feedback para nossa equipe"
          value={text}
          onChangeText={setText}
        />

        {loading && (
          <LoadingRoot>
            <Loader size={36} />
          </LoadingRoot>
        )}

        <Button
          textProps={{ h3: true, centered: true }}
          spacedTop={2}
          padded
          disabled={!like || !text || loading}
          onPress={handleSendFeedback}
        >
          Enviar
        </Button>
      </KeyboardAwareScrollView>
    </Screen>
  );
};

const LoadingRoot = styled.View`
  padding: 12px 0;
`;

const Actions = styled.View`
  padding: 12px 0;
  flex-direction: row;
`;

const Action = styled.View`
  padding: 8px 10px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #00000040;
`;

export default Feedback;

import React from 'react';
import { Platform, ScrollView } from 'react-native';

import styled, { css } from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Text from '$components/General/Text';
import Touchable from '$components/Navigation/Touchable';

import { loadData, storeData } from '$storage';

type Option = {
  key: string;
  label: string;
  value?: string | number;
  icon: React.ReactElement;
  color: string;
};

interface Props {
  options: Option[];
  multiple?: boolean;
  persist?: string;
  onChange?: (options: string[]) => void;
}

const HorizontalFilter: React.FC<Props> = ({ options, multiple, persist, onChange }) => {
  const [checked, setChecked] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!persist) return;

    loadData<string[]>(`hf_${persist}`).then((data) => {
      setChecked(data ?? []);
      if (onChange) onChange(data ?? []);
    });
  }, []);

  function handleSelect(key: string) {
    let newArr: string[] = [];

    if (checked.includes(key)) {
      newArr = [...checked.filter((v) => v !== key)];
    } else {
      if (multiple) newArr = [...checked, key];
      else newArr = [key];
    }

    setChecked(newArr);
    if (onChange) onChange(newArr);
    if (persist) storeData(`hf_${persist}`, newArr);
  }

  return (
    <Root>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <ScrollContent>
          {options.map(({ key, label, icon, value, color }, i) => {
            const hasChecked = checked.length > 0;
            const isChecked = checked.includes(key);

            const iconEl = React.cloneElement(icon, {
              color: isChecked ? '#FFF' : color,
              size: 14,
            });

            return (
              <Touchable key={key} onPress={() => handleSelect(key)}>
                <OptionBlock
                  color={color}
                  checked={isChecked}
                  hasChecked={hasChecked}
                  isFirst={i === 0}
                  isLast={i === options.length - 1}
                >
                  {isChecked && (
                    <OptionDeselectIconView>
                      <AntDesign name="closecircleo" color="#FFF" size={9} />
                    </OptionDeselectIconView>
                  )}

                  <Text p2 centered>
                    {label}
                  </Text>

                  <OptionValue>
                    {!!value?.toString() && (
                      <Text h4 bold spacedRight={0.6}>
                        {value.toString()}
                      </Text>
                    )}

                    {iconEl}
                  </OptionValue>
                </OptionBlock>
              </Touchable>
            );
          })}
        </ScrollContent>
      </ScrollView>
    </Root>
  );
};

const Root = styled.View`
  ${
    Platform.OS === 'web' && css`
      max-width: 880px;
      width: 100%;
      margin: 0 auto;
      `
  }
  margin-bottom: 8px;
`;

const ScrollContent = styled.View`
  flex-direction: row;
`;

type OptionsBlockProps = {
  color: string;
  checked: boolean;
  hasChecked: boolean;
  isFirst: boolean;
  isLast: boolean;
};

const OptionBlock = styled.View<OptionsBlockProps>`
  padding: 6px;
  width: 106px;
  margin-left: ${({ isFirst }) => (isFirst ? 8 : 6)}px;
  margin-right: ${({ isLast }) => (isLast ? 8 : 0)}px;
  background-color: ${({ checked, color }) => (checked ? color : '#00000060')};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  opacity: ${({ hasChecked, checked }) => (hasChecked && !checked ? 0.7 : 1)};
`;

const OptionDeselectIconView = styled.View`
  position: absolute;
  top: 3px;
  right: 3px;
`;

const OptionValue = styled.View`
  padding-top: 2px;
  flex-direction: row;
  align-items: center;
`;

export default HorizontalFilter;

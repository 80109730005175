import React from 'react';
import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { observer } from 'mobx-react-lite';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import Loader from '$components/Feedback/Loader';
import Screen from '$components/Layout/Screen';

import Login from '$screens/Login';

import {
  SessionDocument,
  SessionQuery,
  UpdateDeviceInfoDocument,
  UpdateDeviceInfoMutation,
  UpdateDeviceInfoMutationVariables,
} from '$graphql';

import useStore from './Store';

const Session: React.FC = ({ children }) => {
  const store = useStore();

  const client = React.useMemo(
    () =>
      new ApolloClient({
        uri: Constants?.manifest?.extra?.GRAPH_HTTP_URL,
        cache: new InMemoryCache({
          typePolicies: {
            Account: {
              merge(existing, incoming) {
                return incoming;
              },
            },
            Task: {
              merge(existing, incoming) {
                return incoming;
              },
            },
            Lead: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        }),
        headers: store.token ? { Authorization: store.token } : undefined,
        defaultOptions: {
          mutate: { fetchPolicy: 'network-only', errorPolicy: 'all' },
          query: { fetchPolicy: 'cache-first', errorPolicy: 'all' },
          watchQuery: { fetchPolicy: 'network-only', errorPolicy: 'all' },
        },
      }),
    [store.token]
  );

  // Check session every time ApolloClient changes
  React.useEffect(() => {
    if (store.user) return;

    client
      .query<SessionQuery>({ query: SessionDocument, fetchPolicy: 'network-only' })
      .then(({ data }) => {
        const { token, user } = data.session;

        store.setToken(token);
        store.setUser(user);

        const accounts = user.accounts.data;

        // If have no accounts, deselect current one that is selected
        if (accounts.length === 0) {
          store.setSelectedAccountId(null);
        } else {
          // Check if selected account exists and if it doesn't, select the first one
          const acc = accounts.find((account) => account.id === store.selectedAccountId);
          if (!acc) store.setSelectedAccountId(accounts[0].id);
        }
      })
      .catch(() => store.setUser(null));
  }, [client]);

  // Send device's info to the server
  React.useEffect(() => {
    updateDeviceInfo();
  }, [store.user, store.deviceId]);

  async function updateDeviceInfo() {
    if (!Device.isDevice || !store.user || !store.deviceId) return;

    try {
      if (Platform.OS === 'web') {
        //  FIXME  make web notifications work
        await client.mutate<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>({
          mutation: UpdateDeviceInfoDocument,
          variables: { deviceId: store.deviceId, expoPushToken: 'WEB_NOT_ENABLED' },
        });
      } else {
        const { data: pushToken } = await Notifications.getExpoPushTokenAsync();

        await client.mutate<UpdateDeviceInfoMutation, UpdateDeviceInfoMutationVariables>({
          mutation: UpdateDeviceInfoDocument,
          variables: { deviceId: store.deviceId, expoPushToken: pushToken },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Waits while user state is undefined (user == null means that we have no active session)
  if (store.user === undefined) {
    return (
      <Screen style={{ justifyContent: 'center' }}>
        <Loader size={28} />
      </Screen>
    );
  }

  // We have no active session, show login
  if (!store.user) {
    return (
      <ApolloProvider client={client}>
        <Login />
      </ApolloProvider>
    );
  }

  // We have session, render children
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default observer(Session);

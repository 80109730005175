import React, { ReactNode, useEffect, useState } from 'react';
import { View, Modal, FlatList, Platform, Dimensions } from 'react-native';
import { AntDesign as Icon } from '@expo/vector-icons';

import {
  CloseModal,
  ContainerModal,
  SelectTouchableInput,
  TextSelect,
  Wrapper,
  Option,
  OptionText,
  CloseContainer,
} from './styles';
import { ScrollView } from 'react-native-gesture-handler';

interface SelectProps {
  title?: string;
  chosen?: string;
  children?: ReactNode;
  disabled?: boolean;
}

function Select({
  title,
  chosen,
  children,
  disabled,
}: SelectProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const height = Dimensions.get('screen').height;

  useEffect(() => {
    setModalVisible(false);
  }, [chosen]);

  return (
    <>
      <View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <ContainerModal
            onPress={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <CloseContainer>
              <CloseModal
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                <Icon name="close" color="#d64848" size={30} />
              </CloseModal>
            </CloseContainer>
            <Wrapper>
              <ScrollView style={{ marginBottom: Platform.OS === 'ios' ? 30 : 0 }}>
                {children}  
              </ScrollView>
            </Wrapper>
          </ContainerModal>
        </Modal>
      </View>

      <SelectTouchableInput
        isSelected={chosen ? true : false}
        onPress={() => {
          !disabled ? setModalVisible(!modalVisible) : null;
        }}
      >
        <TextSelect
          isExistChosen={chosen ? true : false}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {chosen || title}
        </TextSelect>
        <Icon 
          style={{right: height > 700 ? 0 : 15}}
          name="caretdown"
          color="rgba(255, 255, 255, 0.87)"
          size={15}
        />
      </SelectTouchableInput>
    </>
  );
}

Select.Option = Option;
Select.OptionText = OptionText;

export default Select;

import React from 'react';
import { Text as RNText } from 'react-native';

import Text, { Props as TextProps } from '$components/General/Text';
import A from '$components/Navigation/A';

export interface Props extends TextProps {}

const urlRegex = /((?:(?:http(?:s)?:\/\/.)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)))/g;

const Linkify = React.forwardRef<RNText, React.ComponentPropsWithoutRef<React.ElementType<Props>>>((props, ref) => {
  if (typeof props.children !== 'string') {
    return <Text {...props} ref={ref} />;
  }

  const items = props.children.split(urlRegex);

  return (
    <Text {...props} ref={ref}>
      {items.map((item, i) => {
        if (urlRegex.test(item))
          return (
            <A to={item.startsWith('http') ? item : `https://${item}`} key={i}>
              {item}
            </A>
          );
        return item;
      })}
    </Text>
  );
});

export default Linkify;

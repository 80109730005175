import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { LinearGradient } from 'expo-linear-gradient';
import { useTheme } from 'styled-components/native';

import Container from '$components/Grid/Container';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const Screen: React.FC<Props> = ({ children, style }) => {
  const insets = useSafeAreaInsets();

  // Theme for background color
  const theme = useTheme();
  const bColor = theme.primary.desaturate(0.75);

  return (
    <LinearGradient
      colors={[bColor.darken(0.5).hex(), bColor.darken(0.75).hex()]}
      style={[{ flex: 1, paddingBottom: insets.bottom }, style]}
    >
      <Container style={{ flex: 1 }}>{children}</Container>
    </LinearGradient>
  );
};

export default Screen;

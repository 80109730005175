import React from 'react';
import {
    Platform, TouchableNativeFeedback, TouchableNativeFeedbackProps, TouchableWithoutFeedback
} from 'react-native';

import styled from 'styled-components/native';

const Touchable: React.FC<TouchableNativeFeedbackProps> = (props) => {
  if (Platform.OS === 'web') {
    const oldStyle = (props.children as any).props.style;
    const newStyle = [{ cursor: 'pointer' }];

    if (Array.isArray(oldStyle)) newStyle.push(...oldStyle);
    else newStyle.push(oldStyle);

    const children = React.cloneElement(props.children as any, {
      style: newStyle,
    });

    return <TouchableWithoutFeedback {...props}>{children}</TouchableWithoutFeedback>;
  }

  return <TouchableNativeFeedback {...props} />;
};

export default Touchable;

import React from 'react';
import { Animated } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';

import { AntDesign, Entypo } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Lottie from '$components/General/Lottie';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useBadgeCountersLazyQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

function Home() {
  const store = useStore();
  const anim = React.useRef(new Animated.Value(0));

  const [getBadgeCounters, { data, loading, error }] = useBadgeCountersLazyQuery();

  React.useEffect(() => {
    let destroyed = false;

    function start() {
      if (destroyed) return;

      Animated.sequence([
        Animated.timing(anim.current, { delay: 2500, toValue: 1, duration: 100, useNativeDriver: true }),
        Animated.timing(anim.current, { toValue: -0.8, duration: 100, useNativeDriver: true }),
        Animated.timing(anim.current, { toValue: 0.5, duration: 100, useNativeDriver: true }),
        Animated.timing(anim.current, { toValue: 0, duration: 100, useNativeDriver: true }),
      ]).start(start);
    }

    start();

    return () => {
      destroyed = true;
    };
  }, []);

  React.useEffect(() => {
    if (!store.selectedAccountId) return;
    getBadgeCounters({ variables: { account: store.selectedAccountId } });
  }, [store.selectedAccountId]);

  const approval = data?.account.stats.tasks.steps.find(({ step }) => step === 'approval')?.count ?? 0;
  const leads =
    data?.account.stats.leads.classifications.find(({ classification }) => classification === 'none')?.count ?? 0;

  const totalLeads = data?.account.stats.leads.classifications.reduce((prev, { count }) => prev + count, 0) ?? 0;

  return (
    <Screen>
      <KeyboardAwareScrollView
        enableOnAndroid
        contentContainerStyle={{ padding: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        extraHeight={200}
      >
        {loading && <Loader size="large" />}

        {error && (
          <Alert error message="Ocorreu um erro ao carregar informações da sua conta" description={error.message} />
        )}

        <Text h1 faded spacedTop spacedBottom={2}>
          Olá, {store.user.name}
        </Text>

        <Touchable onPress={() => navigate('Feedback')}>
          <NewApp
            style={{
              transform: [{ translateX: anim.current.interpolate({ inputRange: [-1, 1], outputRange: [-4, 4] }) }],
            }}
          >
            <Text h2 bold>
              <Entypo name="new" size={18} />
              &nbsp;Nova atualização instalada!
            </Text>
            <Text spacedTop>
              Atualizamos a ID Action e fizemos algumas correções. Dê uma olhadinha e depois retorne aqui para enviar o
              seu feedback sobre o nosso novo aplicativo.
            </Text>
          </NewApp>
        </Touchable>

        <Text h3 faded spacedTop={3} spacedBottom>
          Informações e avisos
        </Text>

        <Touchable onPress={() => navigate(approval > 0 ? 'Approval' : 'Tasks')}>
          <AlertRoot>
            <AlertContent>
              <AlertIcon>
                <AntDesign name="calendar" size={42} color="#FFF" />
              </AlertIcon>

              <AlertTitle>
                <Text h2>Atividades</Text>
                <Text faded>
                  {approval > 0
                    ? 'Não deixe suas aprovações para depois!'
                    : 'Veja todas tividades do seu plano de ação.'}
                </Text>
              </AlertTitle>
            </AlertContent>

            {approval > 0 && (
              <AlertFooter>
                <Lottie
                  autoPlay
                  source={require('./bell.json')}
                  style={{ marginBottom: -5, marginTop: -5, marginRight: -8, width: 32, height: 32 }}
                />

                <Text p1 bold spacedLeft>
                  Você possui {approval} {approval > 1 ? 'atividades' : 'atividade'} aguardando aprovação!
                </Text>
              </AlertFooter>
            )}
          </AlertRoot>
        </Touchable>

        <Touchable onPress={() => navigate('Leads')}>
          <AlertRoot>
            <AlertContent>
              <AlertIcon>
                <AntDesign name="contacts" size={42} color="#FFF" />
              </AlertIcon>

              <AlertTitle>
                <Text h2>Leads</Text>
                {totalLeads > 0 && <Text faded>{totalLeads} pessoas interessadas na sua empresa</Text>}
                {totalLeads === 0 && <Text faded>Pessoas interessadas na sua empresa</Text>}
              </AlertTitle>
            </AlertContent>

            {leads > 0 && (
              <AlertFooter>
                <Lottie
                  autoPlay
                  source={require('./bell.json')}
                  style={{ marginBottom: -5, marginTop: -5, marginRight: -8, width: 32, height: 32 }}
                />

                <Text p1 bold spacedLeft>
                  Você possui {leads} {leads > 1 ? 'leads' : 'lead'} aguardando classificação!
                </Text>
              </AlertFooter>
            )}
          </AlertRoot>
        </Touchable>

        <Text h3 faded spacedTop={3} spacedBottom>
          Estatísticas
        </Text>

        <Alert warning message="Dados não disponíveis no momento" />

        <Footer>
          <Lottie autoPlay source={require('./rocket.json')} style={{ width: 128, height: 128, opacity: 0.4 }} />
        </Footer>
      </KeyboardAwareScrollView>
    </Screen>
  );
}

const AlertRoot = styled.View`
  background-color: ${({ theme }) => theme.primary.hex()}80;
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
`;

const AlertContent = styled.View`
  flex-direction: row;
  padding: 12px 16px;
`;

const AlertFooter = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 6px 12px 6px 0;
  background-color: ${({ theme }) => theme.error.hex()};
`;

const AlertIcon = styled.View`
  padding-right: 16px;
  justify-content: center;
`;

const AlertTitle = styled.View`
  flex: 1;
`;

const NewApp = styled(Animated.View)`
  border-radius: 4px;
  padding: 12px 16px;
  background-color: #09b27fa0;
`;

const Footer = styled.View`
  padding: 24px 0;
  align-items: center;
`;

export default observer(Home);

import React from 'react';
import { FlatList, Platform, View } from 'react-native';

import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Progress from '$components/Feedback/Progress';
import HorizontalFilter from '$components/Filter/HorizontalFilter';
import MonthFilter from '$components/Filter/MonthFilter';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';

import { useAccountTasksLazyQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';

const Tasks: React.FC = () => {
  const store = useStore();

  const [filters, setFilters] = React.useState<string[]>();
  const [month, setMonth] = React.useState<Moment>();

  const [fetchTasks, { data, loading, error, refetch }] = useAccountTasksLazyQuery();

  React.useEffect(() => {
    if (!store.badgeIsDirty) return;
    refetch();
  }, [store.badgeIsDirty]);

  React.useEffect(() => {
    if (!store.selectedAccountId || !month) return;

    fetchTasks({
      variables: {
        account: store.selectedAccountId,
        begin: month.startOf('month').format('YYYY-MM-DD'),
        end: month.endOf('month').format('YYYY-MM-DD'),
      },
    });
  }, [store.selectedAccountId, month?.toISOString()]);

  const now = moment();

  const processedTasks = [...(data?.account.tasks.data ?? [])]
    .sort((x, y) => {
      const xDate = moment(x.finish_date, 'YYYY-MM-DD');
      const yDate = moment(y.finish_date, 'YYYY-MM-DD');

      if (xDate.isBefore(yDate)) return 1;
      if (yDate.isBefore(xDate)) return -1;
      return 0;
    })
    .map((task) => {
      let current_step: 'pending' | 'today' | 'delayed' | 'doing' | 'next' | 'done' | undefined = undefined;

      if (task.step === 'approval') {
        current_step = 'pending';
      } else if (task.step === 'done') {
        current_step = 'done';
      } else {
        const date = moment(task.finish_date, 'YYYY-MM-DD');

        if (task.step === 'finish') {
          current_step = 'doing';
        }

        if (date.isSame(now, 'day')) {
          current_step = 'today';
        } else if (date.isBefore(now)) {
          current_step = 'delayed';
        } else if (current_step === undefined) {
          current_step = 'next';
        }
      }

      return {
        ...task,
        current_step,
      };
    });

  const filteredTasks = processedTasks.filter(
    (task) => filters?.length === 0 || filters?.indexOf(task.current_step) !== -1
  );

  const stats = processedTasks.reduce(
    (prev, curr) => {
      if (curr.current_step === undefined) return prev;
      return { ...prev, [curr.current_step]: (prev[curr.current_step] ?? 0) + 1 };
    },
    {
      pending: 0,
      today: 0,
      delayed: 0,
      doing: 0,
      next: 0,
      done: 0,
    }
  );

  const icons = {
    pending: <AntDesign name="warning" />,
    today: <AntDesign name="carryout" />,
    delayed: <AntDesign name="clockcircleo" />,
    doing: <AntDesign name="reload1" />,
    next: <AntDesign name="calendar" />,
    done: <AntDesign name="checkcircleo" />,
  };

  const colors = {
    pending: '#d64848',
    today: '#EC4C72',
    delayed: '#ff6f1b',
    doing: '#b2ab09',
    next: '#0f81a3',
    done: '#09b27f',
  };

  return (
    <Screen>
      <Filters style={{...getMaxConstraints()}}>
        <FiltersContainer>
          <MonthFilter persist="tasks_month" onChange={(month) => setMonth(month)} />
        </FiltersContainer>

        <Progress
          checked={filters}
          values={[
            { key: 'done', value: stats.done, color: colors.done },
            { key: 'delayed', value: stats.delayed, color: colors.delayed },
            { key: 'today', value: stats.today, color: colors.today },
            { key: 'pending', value: stats.pending, color: colors.pending },
            { key: 'doing', value: stats.doing, color: colors.doing },
            { key: 'next', value: stats.next, color: colors.next },
          ]}
        />

        <HorizontalFilter
          multiple
          persist="tasks_step"
          onChange={(options) => setFilters(options)}
          options={[
            { key: 'delayed', value: stats.delayed, label: 'Atrasado', icon: icons.delayed, color: colors.delayed },
            { key: 'today', value: stats.today, label: 'Para hoje', icon: icons.today, color: colors.today },
            { key: 'pending', value: stats.pending, label: 'Pendente', icon: icons.pending, color: colors.pending },
            { key: 'next', value: stats.next, label: 'Próximo', icon: icons.next, color: colors.next },
            { key: 'doing', value: stats.doing, label: 'Em andamento', icon: icons.doing, color: colors.doing },
            { key: 'done', value: stats.done, label: 'Feito', icon: icons.done, color: colors.done },
          ]}
        />
      </Filters>

      {loading && <Loader size={80} />}
      <View style={{...getMaxConstraints()}}>
        {error && <Alert error message="Ocorreu um erro ao carregar as atividades" description={error.message} />}

        {!loading && !filteredTasks.length && <Alert spaced message="Nenhuma atividade encontrada para o filtro atual" />}
      </View>

      <FlatList
        data={filteredTasks}
        keyExtractor={(task) => task.id}
        ItemSeparatorComponent={TaskSeparator}
        contentContainerStyle={{ paddingHorizontal: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        renderItem={({ item: task, index }) => {
          const icon = React.cloneElement(icons[task.current_step], { color: colors[task.current_step], size: 22 });
          const hasDateComponent = index === 0 || task.finish_date !== filteredTasks[index - 1].finish_date;

          return (
            <>
              {hasDateComponent && (
                <DateSeparator>
                  <Text h4 faded>
                    {moment(task.finish_date, 'YYYY-MM-DD').format('dddd[,] DD [de] MMMM')}
                  </Text>
                </DateSeparator>
              )}

              <Touchable onPress={() => navigate('Task', { id: task.id })}>
                <TaskRoot>
                  <TaskIcon>{icon}</TaskIcon>

                  <Text style={{ flex: 1 }}>{task.title}</Text>

                  <TaskOpenIcon>
                    <AntDesign name="right" color="#FFFFFF80" size={18} />
                  </TaskOpenIcon>
                </TaskRoot>
              </Touchable>
            </>
          );
        }}
      />
    </Screen>
  );
};

export default observer(Tasks);

const Filters = styled.View`
  padding: 8px 0 0;
  background-color: ${({ theme }) => theme.primary.hex()}30;
  margin-top: ${() => (Platform.OS === 'web' ? 12 : 0)}px;
  border-radius: ${() => (Platform.OS === 'web' ? 4 : 0)}px;
`;

const FiltersContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  width: 100%;
`;

const DateSeparator = styled.View`
  padding: 12px 0 4px;
`;

const TaskSeparator = styled.View`
  height: 6px;
`;

const TaskRoot = styled.View`
  flex-direction: row;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.primary.hex()}80;
  border-radius: 4px;
  align-items: center;
`;

const TaskIcon = styled.View`
  padding: 4px 12px 4px 4px;
`;

const TaskOpenIcon = styled.View`
  padding-left: 8px;
`;

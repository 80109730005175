import React from 'react';
import { Platform, Text as RNText } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import * as Linking from 'expo-linking';
import styled from 'styled-components/native';

import Text, { Props as TextProps } from '$components/General/Text';

interface Props extends TextProps {
  to: string;
}

const A: React.FC<Props> = (props) => {
  const ref = React.useRef<RNText>(null);
  const hovered = useHover(ref);

  if (Platform.OS === 'web') {
    return (
      <a href={props.to} target="_blank" style={{ textDecoration: 'none' }}>
        <El {...props} ref={ref} style={hovered ? { textDecorationLine: 'underline' } : undefined} />
      </a>
    );
  }

  return (
    <El
      {...props}
      ref={ref}
      style={hovered ? { textDecorationLine: 'underline' } : undefined}
      onPress={() => Linking.openURL(props.to)}
    />
  );
};

const El = styled(Text)`
  color: ${({ theme }) => `${theme.primary.lighten(0.5)}`};
`;

export default A;

import React from 'react';
import { Animated, TouchableWithoutFeedback } from 'react-native';

import styled from 'styled-components/native';

interface Props {
  idx: number;
  activeIdx: number;
  tabHeight: number;
  handlePress: (idx: number) => void;
}

const InactiveIcon: React.FC<Props> = ({ idx, activeIdx, tabHeight, handlePress, children }) => {
  const prevIdx = React.useRef(activeIdx);

  const anim = React.useRef(new Animated.Value(activeIdx === idx ? 0 : 1)).current;

  React.useEffect(() => {
    // Oculta o botão caso esteja ativo
    if (activeIdx === idx) {
      Animated.timing(anim, {
        toValue: 0,
        duration: 250,
        delay: 50,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(anim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start();

      let ix = 0;

      for (let i = prevIdx.current; i !== activeIdx; i += activeIdx > prevIdx.current ? 1 : -1) {
        if (i === idx) {
          Animated.sequence([
            Animated.timing(anim, {
              toValue: 0.05,
              duration: 100,
              delay: ix * 50,
              useNativeDriver: true,
            }),
            Animated.timing(anim, {
              toValue: 1,
              duration: 300 + ix * 50,
              useNativeDriver: true,
            }),
          ]).start();
          break;
        }

        ix++;
      }
    }

    prevIdx.current = activeIdx;
  }, [idx, activeIdx]);

  const translation = anim.interpolate({ inputRange: [0, 1], outputRange: [tabHeight * 0.1, 0] });

  const style = {
    opacity: anim,
    transform: [{ translateY: translation }],
  };

  return (
    <TouchableWithoutFeedback onPress={() => handlePress(idx)}>
      <Root style={style}>{children}</Root>
    </TouchableWithoutFeedback>
  );
};

const Root = styled(Animated.View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default InactiveIcon;

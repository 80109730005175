import { css } from 'styled-components/native';
import { Platform } from 'react-native';

type ExistsOrNumber = true | number;

type SpacingProps = {
  spaced?: ExistsOrNumber;
  spacedX?: ExistsOrNumber;
  spacedY?: ExistsOrNumber;
  spacedTop?: ExistsOrNumber;
  spacedBottom?: ExistsOrNumber;
  spacedLeft?: ExistsOrNumber;
  spacedRight?: ExistsOrNumber;
};

function getMargins({ spaced, spacedX, spacedY, spacedTop, spacedBottom, spacedLeft, spacedRight }: SpacingProps) {
  function getValue(n: ExistsOrNumber) {
    if (!n) return 0;
    if (n === true) return 8;
    else return n * 8;
  }

  let s = [0, 0, 0, 0];

  if (spaced) {
    const v = getValue(spaced);
    s = [v, v, v, v];
  }

  if (spacedX) {
    const v = getValue(spacedX);
    s[1] = v;
    s[3] = v;
  }

  if (spacedY) {
    const v = getValue(spacedY);
    s[0] = v;
    s[2] = v;
  }

  if (spacedTop) s[0] = getValue(spacedTop);
  if (spacedBottom) s[2] = getValue(spacedBottom);
  if (spacedLeft) s[3] = getValue(spacedLeft);
  if (spacedRight) s[1] = getValue(spacedRight);

  return s;
}

type PaddingProps = {
  padded?: ExistsOrNumber;
  paddedX?: ExistsOrNumber;
  paddedY?: ExistsOrNumber;
  paddedTop?: ExistsOrNumber;
  paddedBottom?: ExistsOrNumber;
  paddedLeft?: ExistsOrNumber;
  paddedRight?: ExistsOrNumber;
};

function getPadding({ padded, paddedX, paddedY, paddedTop, paddedBottom, paddedLeft, paddedRight }: PaddingProps) {
  function getValue(n: ExistsOrNumber) {
    if (!n) return 0;
    if (n === true) return 8;
    else return n * 8;
  }

  let s = [0, 0, 0, 0];

  if (padded) {
    const v = getValue(padded);
    s = [v, v, v, v];
  }

  if (paddedX) {
    const v = getValue(paddedX);
    s[1] = v;
    s[3] = v;
  }

  if (paddedY) {
    const v = getValue(paddedY);
    s[0] = v;
    s[2] = v;
  }

  if (paddedTop) s[0] = getValue(paddedTop);
  if (paddedBottom) s[2] = getValue(paddedBottom);
  if (paddedLeft) s[3] = getValue(paddedLeft);
  if (paddedRight) s[1] = getValue(paddedRight);

  return s;
}

export interface StyleProps extends SpacingProps, PaddingProps {}

export function getBaseStyles(props: StyleProps) {
  const m = getMargins(props);
  const p = getPadding(props);

  return css`
    padding: ${p[0]}px ${p[1]}px ${p[2]}px ${p[3]}px;
    margin: ${m[0]}px ${m[1]}px ${m[2]}px ${m[3]}px;
  `;
}

export function getMaxConstraints() {
  if (Platform.OS === 'web') {
    return {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 880,
      width: '100%'
    };
  }

  return {};
}

import * as React from 'react';

import { NavigationContainerRef } from '@react-navigation/core';

export const navigationRef = React.createRef<NavigationContainerRef>();

export function getNavigation() {
  return navigationRef.current;
}

export function navigate(name: string, params?: any) {
  navigationRef.current?.navigate(name, params);
}

export function goBack() {
  navigationRef.current?.goBack();
}

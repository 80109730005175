import React from 'react';
import { Platform, ViewStyle } from 'react-native';

import styled from 'styled-components/native';

interface Props {
  style?: ViewStyle;
}

const Container: React.FC<Props> = (props) => {
  if (Platform.OS !== 'web') return <AppRoot style={props.style}>{props.children}</AppRoot>;
  return <WebRoot style={props.style}>{props.children}</WebRoot>;
};

const AppRoot = styled.View``;

const WebRoot = styled.View`
  width: 100%;
  max-width: 100%;
  margin: auto;
`;

export default Container;

import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import styled from 'styled-components/native';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const Frame: React.FC<Props> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

const Container = styled.View`
  background-color: #46464660;
  border-radius: 2px;
  padding: 16px;
`;

export default Frame;

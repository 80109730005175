import React from 'react';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Pagination } from 'react-native-snap-carousel';

import styled, { useTheme } from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';

import Loader from '$components/Feedback/Loader';
import Touchable from '$components/Navigation/Touchable';

import { goBack } from '$navigation';

interface Props {
  route: any;
}

const ImagesViewer: React.FC<Props> = ({ route }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  const [active, setActive] = React.useState(route.params.index ?? 0);

  function handleClose() {
    goBack();
  }

  return (
    <Root style={{ paddingBottom: 72 + insets.bottom }}>
      <ImageViewer
        enablePreload
        enableSwipeDown
        renderHeader={() => (
          <CloseButtonView>
            <Touchable onPress={handleClose}>
              <AntDesign name="closecircle" color="#dddddd" size={22} />
            </Touchable>
          </CloseButtonView>
        )}
        footerContainerStyle={{ top: 0, right: 0, bottom: undefined }}
        index={route.params.index}
        imageUrls={route.params.images.map((image: string) => ({ url: image }))}
        onCancel={handleClose}
        loadingRender={() => <Loader />}
        renderIndicator={() => <></>}
        onChange={setActive}
      />

      <Pagination
        dotsLength={route.params.images.length}
        activeDotIndex={active}
        dotStyle={{ width: 12, height: 12, borderRadius: 6, backgroundColor: theme.primary.hex() }}
        containerStyle={{ paddingVertical: 0, paddingTop: 8 }}
      />
    </Root>
  );
};

const Root = styled.View`
  flex: 1;
  background-color: #000;
`;

const CloseButtonView = styled.View`
  position: absolute;
  z-index: 9999;
  top: 16px;
  right: 16px;
`;

export default ImagesViewer;

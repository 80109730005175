import React, { useEffect } from 'react';

import { Button as Btn, FlatList, Platform, View } from 'react-native';

import '@expo/match-media'
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import styled, { css } from 'styled-components/native';

import { useMediaQuery } from "react-responsive";
import { AntDesign, Feather, FontAwesome } from '@expo/vector-icons';

import Alert from '$components/Feedback/Alert';
import Loader from '$components/Feedback/Loader';
import Progress from '$components/Feedback/Progress';
import HorizontalFilter from '$components/Filter/HorizontalFilter';
import Text from '$components/General/Text';
import Screen from '$components/Layout/Screen';
import Touchable from '$components/Navigation/Touchable';
import Button from '$components/General/Button';
import Input from '$components/DataEntry/Input';
import Select from '$components/DataEntry/Select';

import { useAccountLeadsLazyQuery } from '$graphql';
import { navigate } from '$navigation';
import useStore from '$store';
import { getMaxConstraints } from '$logic/style';
import InputDate from '$components/DataEntry/InputDate';

const LeadsFilter: React.FC = () => {
  const store = useStore();
  const isMobile = useMediaQuery({ maxWidth: 540 });

  const [filters, setFilters] = React.useState<string[]>();
  const [initialDate, setInitialDate] = React.useState<string>();
  const [finalDate, setFinalDate] = React.useState<string>();
  const [errorDateMessage, setErrorDateMessage] = React.useState<string>('');

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [source, setSource] = React.useState<any>({ value: '', label: 'Qualquer' });
  const [searchClicked, setSearchClicked] = React.useState(true);
  
  const [fetchLeads, { data, loading, error, refetch }] = useAccountLeadsLazyQuery();

  React.useEffect(() => {
    if (!store.selectedAccountId || !initialDate || !finalDate) {
      setSearchClicked(false);
      return;
    }

    if (searchClicked) {
      let variables: any = {
        account: store.selectedAccountId,
        begin: moment(initialDate).format('YYYY-MM-DD'),
        end: moment(finalDate).format('YYYY-MM-DD'),
        name,
        email,
        phone
      };

      if (source?.value) variables = { ...variables, sources: [source?.value] };

      fetchLeads({ variables });
      setSearchClicked(false);
    }
  }, 
  [store.selectedAccountId, searchClicked]);

  React.useEffect(() => {
    if (!store.mustRefetchLeads || !refetch) return;
    store.setMustRefetchleads(false);
    refetch();
  }, [store.mustRefetchLeads]);

  const processedLeads = [...(data?.account.leads.data ?? [])];

  const filteredLeads = processedLeads.filter(
    (lead) => filters?.length === 0 || filters?.indexOf(lead.classification) !== -1
  );

  const stats = processedLeads.reduce(
    (prev, curr) => {
      return { ...prev, [curr.classification]: (prev[curr.classification] ?? 0) + 1 };
    },
    {
      none: 0,
      no_contact: 0,
      has_profile: 0,
      no_profile: 0,
      closed: 0,
    }
  );

  const classificationIcons: any = {
    none: <AntDesign name="questioncircleo" />,
    no_contact: <Feather name="user-x" size={16} />,
    has_profile: <Feather name="user-check" size={16} />,
    no_profile: <Feather name="user" size={16} />,
    closed: <FontAwesome name="money" size={16} />,
  };

  const sourceIcons: any = {
    none: <FontAwesome name="user-circle-o" />,
    facebook: <FontAwesome name="facebook-official" />,
    whatsapp: <FontAwesome name="whatsapp" />,
    website: <FontAwesome name="globe" />,
    linkedin: <FontAwesome name="linkedin-square" />,
  };

  const sources: any = [
    { value: '', label: 'Qualquer' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'whatsapp', label: 'Whatsapp' },
    { value: 'website', label: 'Website' },
    { value: 'linkedin', label: 'Linkedin' },
  ];

  const colors: any = {
    none: '#EC4C72',
    no_contact: '#d64848',
    has_profile: '#0f81a3',
    no_profile: '#ff6f1b',
    closed: '#09b27f',
  };

  const handleSetSource = (src: any) => {
    setSource({
      label: src?.label,
      value: src?.value
    });
  };

  const handleTurnBack = () => {
    navigate("Leads");
  };

  const handleSearch = () => {
    setErrorDateMessage('');

    const firstDate = moment(initialDate, 'YYYY-MM-DD', true);
    const lastDate = moment(finalDate, 'YYYY-MM-DD', true);

    if (firstDate.isValid() !== true) {
      setErrorDateMessage('A data inicial não é válida!');
      return;
    }
    
    if (lastDate.isValid() !== true) {
      setErrorDateMessage('A data final não é válida!');
      return;
    }
    
    if (firstDate.isAfter(lastDate)) {
      setErrorDateMessage('A data inicial é maior que a data final!');
      return;
    }

    setSearchClicked(true);
  };

  return (
    <Screen>
      <FlatList
        data={filteredLeads}
        keyExtractor={(lead) => lead.id}
        ItemSeparatorComponent={LeadSeparator}
        contentContainerStyle={{ paddingHorizontal: 8, paddingBottom: 72, ...getMaxConstraints() }}
        scrollIndicatorInsets={{ bottom: 50 }}
        renderItem={({ item: lead, index }) => {
          const classificationIcon = React.cloneElement(classificationIcons[lead.classification], {
            color: colors[lead.classification],
            size: 22,
          });

          const sourceIcon = React.cloneElement(sourceIcons[lead.source], { color: '#ffffff50', size: 12 });

          let sourceText = '';
          if (lead.source === 'whatsapp') sourceText = 'WhatsApp';
          if (lead.source === 'facebook') sourceText = 'Facebook';
          if (lead.source === 'linkedin') sourceText = 'Linkedin';
          if (lead.source === 'website') sourceText = 'Website';

          const subscribedAt = moment.utc(lead.subscribed_at, 'YYYY-MM-DD HH:mm:ss').local();

          const hasDateComponent =
            index === 0 ||
            !subscribedAt.isSame(
              moment.utc(filteredLeads[index - 1].subscribed_at, 'YYYY-MM-DD HH:mm:ss').local(),
              'day'
            );

          return (
            <>
              {hasDateComponent && (
                <DateSeparator>
                  <Text h4 faded>
                    {subscribedAt.format('dddd[,] DD [de] MMMM')}
                  </Text>
                </DateSeparator>
              )}

              <Touchable onPress={() => navigate('Lead', { id: lead.id })}>
                <LeadRoot>
                  <LeadIcon>{classificationIcon}</LeadIcon>

                  <LeadContent>
                    <Text>{lead.name}</Text>

                    {lead.source !== 'none' && (
                      <LeadSource>
                        <LeadSourceIcon>{sourceIcon}</LeadSourceIcon>
                        <Text faded p3>
                          via {sourceText}
                        </Text>
                      </LeadSource>
                    )}
                  </LeadContent>

                  <LeadOpenIcon>
                    <AntDesign name="right" color="#FFFFFF80" size={18} />
                  </LeadOpenIcon>
                </LeadRoot>
              </Touchable>
            </>
          );
        }}

        ListHeaderComponent={
          <>
            <Filters style={{...getMaxConstraints()}}>
              <FiltersContainer>
                <Touchable onPress={handleTurnBack}>
                  <ChangeButton>
                    <AntDesign name="back" color="#FFF" size={16} />
                  </ChangeButton>
                </Touchable>
                <ChangeButton>
                  <AntDesign name="filter" color="#FFF" size={16} />
                </ChangeButton>       
              </FiltersContainer>

              <FilterContainerInputs>
                <FilterContainerInputsContent {...{isMobile}}>
                  <InputContent isFirst {...{isMobile}}>
                    <InputContentText>De:</InputContentText>
                    <InputDate 
                      placeholder="De"
                      value={initialDate}
                      onChange={setInitialDate}
                    />
                  </InputContent>
                  <InputContent {...{isMobile}}>
                    <InputContentText>Até:</InputContentText>
                    <InputDate 
                      placeholder="Até"
                      value={finalDate}
                      onChange={setFinalDate}
                    />
                  </InputContent>
                </FilterContainerInputsContent>
                <FilterContainerInputsContent {...{isMobile}}>
                  <InputContent isFirst {...{isMobile}}>
                    <InputContentText>Nome:</InputContentText>
                    <StyledInput
                      placeholder="Nome"
                      value={name}
                      onChangeText={setName}
                    />
                  </InputContent>
                  <InputContent {...{isMobile}}>
                    <InputContentText>Telefone:</InputContentText>
                    <StyledInput
                      placeholder="Telefone"
                      keyboardType="phone-pad"
                      value={phone}
                      onChangeText={setPhone}
                    />
                  </InputContent>
                </FilterContainerInputsContent>
                <FilterContainerInputsContent {...{isMobile}}>       
                  <InputContent isFirst {...{isMobile}}>
                    <InputContentText>Email:</InputContentText>
                      <StyledInput
                        placeholder="E-mail"
                        value={email}
                        onChangeText={setEmail}
                      />
                  </InputContent>
                  <InputContent isFirst {...{isMobile}}>
                    <InputContentText>Origem:</InputContentText>
                    <Select title="Origem" chosen={source?.label}>
                      {sources.map((src: any) => (
                        <Select.Option
                          key={src?.value}
                          onPress={() =>
                            handleSetSource({
                              value: src?.value,
                              label: src?.label
                            })
                          }
                        >
                          <Select.OptionText>{src?.label}</Select.OptionText>
                        </Select.Option>
                      ))}
                    </Select>
                  </InputContent>
                </FilterContainerInputsContent>
                <FilterContainerInputsContent {...{isMobile}} style={{justifyContent: 'flex-end'}}>
                  <Button spacedY padded onPress={() => handleSearch()}>
                    Pesquisar
                  </Button>
                </FilterContainerInputsContent>
              </FilterContainerInputs>

              <Progress
                checked={filters}
                values={[
                  { key: 'none', value: stats.none, color: colors.none },
                  { key: 'no_contact', value: stats.no_contact, color: colors.no_contact },
                  { key: 'has_profile', value: stats.has_profile, color: colors.has_profile },
                  { key: 'no_profile', value: stats.no_profile, color: colors.no_profile },
                  { key: 'closed', value: stats.closed, color: colors.closed },
                ]}
              />

              <HorizontalFilter
                multiple
                persist="leads_step"
                onChange={(options) => setFilters(options)}
                options={[
                  {
                    key: 'none',
                    value: stats.none,
                    label: 'Não contatado',
                    icon: classificationIcons.none,
                    color: colors.none,
                  },
                  {
                    key: 'no_contact',
                    value: stats.no_contact,
                    label: 'Sem contato',
                    icon: classificationIcons.no_contact,
                    color: colors.no_contact,
                  },
                  {
                    key: 'has_profile',
                    value: stats.has_profile,
                    label: 'Com perfil',
                    icon: classificationIcons.has_profile,
                    color: colors.has_profile,
                  },
                  {
                    key: 'no_profile',
                    value: stats.no_profile,
                    label: 'Sem perfil',
                    icon: classificationIcons.no_profile,
                    color: colors.no_profile,
                  },
                  {
                    key: 'closed',
                    value: stats.closed,
                    label: 'Fechado',
                    icon: classificationIcons.closed,
                    color: colors.closed,
                  },
                ]}
              />
            </Filters>

            {loading && <Loader size={80} />}

            <View style={{...getMaxConstraints()}}>
              {!!errorDateMessage ? <Alert error spaced message={errorDateMessage} /> : <></>}
              {error && <Alert error message="Ocorreu um erro ao carregar as atividades" description={error.message} />}

              {!loading && !filteredLeads.length && <Alert spaced message="Nenhum lead encontrado para o filtro atual" />}
            </View>
          </>
        }
      />
    </Screen>
  );
};

export default observer(LeadsFilter);

const Filters = styled.View`
  padding: 8px 0 0;
  background-color: ${({ theme }) => theme.primary.hex()}30;
  margin-top: ${() => (Platform.OS === 'web' ? 12 : 0)}px;
  border-radius: ${() => (Platform.OS === 'web' ? 4 : 0)}px;
`;

const FiltersContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
`;

const DateSeparator = styled.View`
  padding: 12px 0 4px;
`;

const LeadSeparator = styled.View`
  height: 6px;
`;

const LeadRoot = styled.View`
  flex-direction: row;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.primary.hex()}80;
  border-radius: 4px;
  align-items: center;
`;

const LeadIcon = styled.View`
  padding: 4px 12px 4px 4px;
`;

const LeadContent = styled.View`
  flex: 1;
  justify-content: center;
`;

const LeadSource = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LeadSourceIcon = styled.View`
  padding: 0 4px 0 0;
`;

const LeadOpenIcon = styled.View`
  padding-left: 8px;
`;

const ChangeButton = styled.View`
  width: 40px;
  height: 40px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary.hex()};
  border-radius: 4px;
`;

const FilterContainerInputs = styled.View`
  ${
    Platform.OS === 'web' && css`
      max-width: 880px;
      width: 100%;
      margin: 0 auto;
    `
  }
  padding: 0 8px;
`;

const FilterContainerInputsContent = styled.View<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (!isMobile && Platform.OS === 'web' ? 'row' : 'column')};
  margin: 0 auto;
  margin-bottom: 8px;
  width: 100%;
`
const StyledInput = styled(Input)`
  width: 100%;
`

const InputContent = styled.View<{ isFirst?: boolean, isMobile: boolean }>`
  margin-right: ${({ isFirst }) => (isFirst && Platform.OS === 'web' ? 8 : 0)}px;
  width: ${({ isMobile }) => (!isMobile && Platform.OS === 'web' ? 'calc(50% - 4px)' : '100%')};
`

const InputContentText = styled(Text)`
  margin: 4px 0;
`;
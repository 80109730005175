import React from 'react';

import styled from 'styled-components/native';

import Text from '$components/General/Text';

type Value = {
  key: string;
  value: number;
  color: string;
};

interface Props {
  values: Value[];
  checked?: string[];
}

const Progress: React.FC<Props> = ({ values, checked }) => {
  const max = values.reduce((prev, curr) => prev + curr.value, 0);
  if (values.length === 0) return null;

  return (
    <Root>
      {values.map((value, i) => {
        const hasChecked = checked ? checked.length > 0 : false;
        const perc = (value.value / max) * 100;

        return (
          <Value
            key={value.key}
            value={value.value}
            color={value.color}
            checked={checked ? checked.includes(value.key) : false}
            hasChecked={hasChecked}
          >
            {perc >= 10 && (
              <Text centered bold p2>
                {perc.toFixed(0)}%
              </Text>
            )}
          </Value>
        );
      })}
    </Root>
  );
};

const Root = styled.View`
  margin: 0 8px 8px;
  height: 20px;
  flex-direction: row;
  background-color: #00000040;
  border-radius: 4px;
  overflow: hidden;
`;

const Value = styled.View<Value & { checked: boolean; hasChecked: boolean }>`
  flex: ${({ value }) => value};
  padding: 2px 0;
  background-color: ${({ color, hasChecked, checked }) => (hasChecked && !checked ? 'transparent' : color)};
`;

export default Progress;

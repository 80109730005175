import React from 'react';
import 'react-native-get-random-values';

import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { loadData, storeData } from './Storage';

class Store {
  deviceId?: string = undefined;
  token?: string | null = undefined;
  user?: any = undefined;
  selectedAccountId?: string | null = undefined;
  badgeIsDirty: boolean = true;
  hasUpdate: boolean = false;
  mustRefetchLeads: boolean = false;

  constructor() {
    makeObservable(this, {
      deviceId: observable,
      token: observable,
      user: observable,
      selectedAccountId: observable,
      badgeIsDirty: observable,
      hasUpdate: observable,
      mustRefetchLeads: observable,
      setDeviceId: action,
      setToken: action,
      setUser: action,
      setSelectedAccountId: action,
      setBadgeDirty: action,
      setHasUpdate: action,
    });

    loadData<string>('device_id').then((deviceId) => this.setDeviceId(deviceId ?? uuidv4()));
    loadData<string>('token').then((token) => this.setToken(token ?? null));
    loadData<string>('selected_account_id').then((accountId) => this.setSelectedAccountId(accountId ?? null));
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
    if (deviceId) storeData('device_id', deviceId);
  }

  setToken(token: string | null) {
    this.token = token;
    if (token) storeData('token', token);
  }

  //  FIXME  tipo do usuário
  setUser(user: any) {
    this.user = user;
  }

  setSelectedAccountId(accountId: string | null) {
    this.selectedAccountId = accountId;
    this.badgeIsDirty = true;
    if (accountId) storeData('selected_account_id', accountId);
  }

  setBadgeDirty(dirty: boolean) {
    this.badgeIsDirty = dirty;
  }

  setHasUpdate(hasUpdate: boolean) {
    this.hasUpdate = hasUpdate;
  }

  setMustRefetchleads(mustRefetchLeads: boolean) {
    this.mustRefetchLeads = mustRefetchLeads;
  }
}

const store = new Store();

const storeContext = React.createContext(store);

export default function useStore() {
  return React.useContext(storeContext);
}
